import { createTxs } from '@koolumbus/web/utils';
import React, { useState } from 'react';
import SignInForm from './SignInForm';
import SignUpForm from './SignUpForm';
import SocialLogin from './SocialLogin';

interface AuthFormProps {
  initialType?: 'login' | 'signup';
}

const AuthForm: React.FC<React.PropsWithChildren<AuthFormProps>> = ({ initialType = 'login' }) => {
  const tx = useTxs();

  const [type, setType] = useState<'login' | 'signup'>(initialType);
  const isLogin = type === 'login';

  return (
    <div className="flex-1 flex flex-col justify-center lg:flex-none">
      <div className="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <h2 className="mt-4 text-2xl md:text-3xl font-extrabold text-gray-900">
            {tx.title(isLogin)}
          </h2>
          <p className="mt-1 md:mt-2 text-lg text-gray-600">
            {tx.or}{' '}
            <button
              role="button"
              onClick={() => setType((v) => (v === 'login' ? 'signup' : 'login'))}
              className="font-medium text-primary hover:text-primary-600"
            >
              {tx.subtitle(isLogin)}
            </button>
          </p>
        </div>

        <div className="mt-8">
          <div>
            <SocialLogin />

            <div className="mt-6 relative">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">{tx.orContinueWith}</span>
              </div>
            </div>
          </div>

          <div className="mt-6">{isLogin ? <SignInForm /> : <SignUpForm />}</div>
        </div>
      </div>
    </div>
  );
};

const useTxs = createTxs({
  en: {
    title: (isLogin: boolean) =>
      (isLogin ? 'Sign in to your account' : 'Create a new account') as string,
    or: 'Or',
    subtitle: (isLogin: boolean) =>
      (isLogin ? 'create a new account' : 'sign in to your account') as string,
    orContinueWith: 'Or continue with',
  },
  it: {
    title: (isLogin: boolean) => (isLogin ? 'Accedi al tuo account' : 'Crea il tuo account'),
    or: 'Oppure',
    subtitle: (isLogin: boolean) => (isLogin ? 'crea un nuovo account' : 'accedi al tuo account'),
    orContinueWith: 'O continua con',
  },
});

export default AuthForm;

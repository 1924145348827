import { ConfirmDialog, MaterialIcon, useToast } from '@koolumbus/web/ui';
import { createTxs } from '@koolumbus/web/utils';
import { default as React, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuthCtx } from '../../lib/context/auth.context';
import { trpc } from '../../lib/trpc';

interface SignInFormValue {
  email: string;
  password: string;
}

interface NewSignInFormProps {}

const SignInForm: React.FC<React.PropsWithChildren<NewSignInFormProps>> = () => {
  const toast = useToast();
  const auth = useAuthCtx();
  const tx = useTxs();
  const [showPassword, setShowPassword] = useState(false);
  const [isResetPasswordConfirmOpen, setResetPasswordConfirmOpen] = useState(false);

  const {
    formState: { errors },
    handleSubmit,
    register,
    formState: { isSubmitting },
    trigger,
    watch,
  } = useForm<SignInFormValue>({
    mode: 'onTouched',
  });

  const email = watch('email');

  const validateEmail = (value: string): string | boolean => {
    if (!value) {
      return tx.email.pleaseProvide;
    }

    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (!emailRegex.test(value)) {
      return tx.email.pleaseProvide;
    }

    return true;
  };

  const validatePassword = (value: string): string | boolean => {
    if (!value) {
      return tx.password.pleaseProvide;
    }

    return true;
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      await auth.signInWithPassword(data);
    } catch (err) {
      console.error(err);
      toast.error();
    }
  });

  const { mutateAsync: performResetPassword } = trpc.auth.resetPassword.useMutation({
    onSuccess: () => {
      toast.success(tx.password.forgotSent);
    },
    onError: (err) => {
      if (err.data?.code === 'NOT_FOUND') {
        toast.warning(tx.password.emailNotFound);
      } else {
        toast.error();
      }
    },
  });

  const onForgotPassword = async () => {
    if (await trigger('email')) {
      setResetPasswordConfirmOpen(true);
    }
  };

  const onTogglePswVisibility: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPassword((v) => !v);
  };

  return (
    <>
      <form onSubmit={onSubmit} className="space-y-5">
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            {tx.email.label}
          </label>
          <div className="mt-1">
            <input
              id="email"
              type="email"
              autoComplete="email"
              className="text-input"
              aria-describedby="email-error"
              data-valid={!errors.email}
              {...register('email', { validate: validateEmail })}
            />

            {errors.email && (
              <p className="input-error" id="email-error">
                {errors.email.message}
              </p>
            )}
          </div>
        </div>

        <div className="space-y-1">
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">
            Password
          </label>

          <div className="mt-1">
            <div className="relative">
              <input
                id="password"
                type="password"
                autoComplete="current-password"
                data-valid={!errors.password}
                className="text-input pr-14"
                {...register('password', { validate: validatePassword })}
              />

              <span className="absolute inset-y-0 right-0 pr-1.5 flex items-center">
                <button
                  type="button"
                  className="flex-center bg-gray-100 h-9 w-9 rounded-md"
                  onClick={onTogglePswVisibility}
                  aria-label={tx.password.showOrHide}
                >
                  <MaterialIcon
                    icon={showPassword ? 'visibility-off' : 'visibility'}
                    className="text-gray-500"
                    aria-hidden="true"
                  />
                </button>
              </span>
            </div>

            {errors.password && (
              <p className="input-error" id="email-error">
                {errors.password.message}
              </p>
            )}
          </div>
        </div>

        <div className="flex items-center">
          <div className="text-sm">
            <button
              type="button"
              onClick={onForgotPassword}
              className="font-medium text-primary hover:text-primary-600"
            >
              {tx.password.forgot}
            </button>
          </div>
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className="w-full h-11 flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm font-medium text-white bg-primary hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-60"
        >
          {tx.signIn}
        </button>
      </form>

      <ConfirmDialog
        title="Forgot password"
        body={tx.password.forgotConfirm(email)}
        isOpen={isResetPasswordConfirmOpen}
        onClose={() => setResetPasswordConfirmOpen(false)}
        onConfirm={() => performResetPassword({ email })}
      />
    </>
  );
};

const useTxs = createTxs({
  en: {
    email: {
      label: 'Email address',
      pleaseProvide: 'Please provide a valid email address',
    },
    password: {
      pleaseProvide: 'Please provide a password',
      showOrHide: 'Show or hide password',
      forgot: 'Forgot your password?',
      forgotConfirm: (email: string) => {
        return `An email with a link to reset your password will be sent to ${email}. Do you want to proceed?`;
      },
      forgotSent: 'Successfully sent, please check your email inbox',
      emailNotFound: 'This email address does not belong to any user',
    },
    signIn: 'Sign in',
  },
  it: {
    email: {
      label: 'Indirizzo email',
      pleaseProvide: 'Inserisci un indirizzo email valido',
    },
    password: {
      pleaseProvide: 'Inserisci una password',
      showOrHide: 'Mostra o nascondi password',
      forgot: 'Hai dimenticato la password?',
      forgotConfirm: (email: string) => {
        return `Una email con un link per reimpostare la password verrà inviata all'indirizzo ${email}. Vuoi procedere?`;
      },
      forgotSent: 'Email inviata con successo, controlla la tua casella di posta.',
      emailNotFound: 'Questo indirizzo email non appartiene ad alcun utente',
    },
    signIn: 'Entra',
  },
});

export default SignInForm;

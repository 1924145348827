import createStore from 'zustand';

type FireChatsStoreState = {
  chatsToRead: string[];
  setChatsToRead: (value: string[]) => void;
};

export const useFireChatsStore = createStore<FireChatsStoreState>((set) => ({
  chatsToRead: [],
  setChatsToRead: (value) => set({ chatsToRead: value }),
}));

import { Avatar, ConfirmDialog, Drawer, MaterialIcon, MaterialIconName } from '@koolumbus/web/ui';
import { useLocale } from '@koolumbus/web/utils';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useAuthCtx } from '../../../lib/context/auth.context';
import { primaryMenuItems, secondaryMenuItems } from './menu-items';

interface MobileDrawerProps {
  isOpen: boolean;
  onSignIn: () => void;
  onSignUp: () => void;
  onClose: () => void;
}

const MobileDrawer: React.FC<React.PropsWithChildren<MobileDrawerProps>> = ({
  isOpen,
  onClose,
  onSignIn,
  onSignUp,
}) => {
  const {
    state: { isLoggedIn, user },
    signOut,
  } = useAuthCtx();
  const router = useRouter();

  const onProfile = () => {
    onClose();
    router.push('/me/settings');
  };

  const [confirmLogoutOpen, setConfirmLogoutOpen] = useState(false);
  const onCloseConfirmLogout = () => setConfirmLogoutOpen(false);

  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} size="sm" className="max-w-xs">
        <div className="flex items-center pl-6 h-[100px] bg-gray-50">
          {isLoggedIn ? (
            <div className="flex items-center cursor-pointer" onClick={onProfile}>
              <Avatar />
              <div className="ml-5">
                <p className="text-lg font-medium">{user?.fullName || 'My Profile'}</p>
                <p className="text-gray-500">{user!.email}</p>
              </div>
            </div>
          ) : (
            <AuthButtons floating={false} onSignIn={onSignIn} onSignUp={onSignUp} />
          )}
        </div>

        <div className="relative flex-1">
          <MobileMenu closeDrawer={onClose} openLogoutConfirm={() => setConfirmLogoutOpen(true)} />
        </div>
      </Drawer>

      <ConfirmDialog
        onClose={onCloseConfirmLogout}
        isOpen={confirmLogoutOpen}
        onConfirm={signOut}
        title="Logout"
        body="Do you really want to logout?"
        variant="warning"
      />
    </>
  );
};

interface AuthButtonsProps {
  floating: boolean;
  onSignIn: () => void;
  onSignUp: () => void;
}

const AuthButtons: React.FC<React.PropsWithChildren<AuthButtonsProps>> = ({
  floating,
  onSignIn,
  onSignUp,
}) => {
  return (
    <div className="flex items-center justify-between">
      <button
        onClick={onSignIn}
        className="text-base font-medium px-4 py-2 rounded-md border text-gray-600 hover:text-gray-900"
      >
        Sign in
      </button>

      <button
        onClick={onSignUp}
        className="ml-4 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-primary-600"
      >
        Sign up
      </button>
    </div>
  );
};

interface MobileMenuProps {
  closeDrawer: () => void;
  openLogoutConfirm: () => void;
}

const MobileMenu: React.FC<React.PropsWithChildren<MobileMenuProps>> = ({
  closeDrawer,
  openLogoutConfirm,
}) => {
  const {
    state: { isLoggedIn },
  } = useAuthCtx();
  const locale = useLocale();

  const onLogout = () => {
    openLogoutConfirm();
    closeDrawer();
  };

  return (
    <ul className="pl-4 mt-6 space-y-3">
      <MobileMenuItem label="Home" icon="home" action="/" closeDrawer={closeDrawer} />

      {isLoggedIn ? (
        <>
          {primaryMenuItems.map((item) => (
            <MobileMenuItem
              key={item.href}
              label={item.label[locale]}
              icon={item.icon}
              action={item.href}
              closeDrawer={closeDrawer}
            />
          ))}

          <hr />

          {secondaryMenuItems.map((item) => (
            <MobileMenuItem
              key={item.href}
              label={item.label[locale]}
              icon={item.icon}
              action={item.href}
              closeDrawer={closeDrawer}
            />
          ))}

          <MobileMenuItem
            label="Logout"
            icon="lock-open"
            action={onLogout}
            closeDrawer={closeDrawer}
          />
        </>
      ) : (
        <MobileMenuItem
          label="FOR LANDLORDS"
          icon="account-circle"
          action="/#landlords"
          closeDrawer={closeDrawer}
        />
      )}
    </ul>
  );
};

type MobileMenuItemProps = {
  label: string;
  icon: MaterialIconName;
  action: string | (() => void);
  closeDrawer: () => void;
};

const MobileMenuItem: React.FC<React.PropsWithChildren<MobileMenuItemProps>> = ({
  label,
  icon,
  action,
  closeDrawer,
}) => {
  const router = useRouter();

  const onPushRoute = (href: string) => {
    router.push(href);
    closeDrawer();
  };

  return (
    <li
      className="flex items-center h-11 pl-2 text-lg text-gray-600 font-medium"
      onClick={typeof action === 'string' ? () => onPushRoute(action) : action}
    >
      <MaterialIcon className="text-gray-400 text-xl mr-4" variant="outlined" icon={icon} />
      {label}
    </li>
  );
};

export default MobileDrawer;

import clsx from 'clsx';
import React from 'react';

interface NumberInputStepperProps {
  value: number;
  onChange: (value: number) => void;
  min: number;
  max: number;
  step?: number;
}

export const NumberInputStepper: React.FC<React.PropsWithChildren<NumberInputStepperProps>> = ({
  min: minValue,
  max: maxValue,
  value,
  onChange,
  step = 1,
}) => {
  const onIncrement = () => {
    if (value <= maxValue - step) {
      onChange(value + step);
    }
  };

  const onDecrement = () => {
    if (value >= minValue + step) {
      onChange(value - step);
    }
  };

  return (
    <div className="flex">
      <StepButton label="-" onClick={onDecrement} isDisabled={value < minValue + step} />

      <input
        className="w-14 text-center text-lg bg-transparent border-0 focus:ring-0 select-none"
        value={value}
        readOnly
      />

      <StepButton label="+" onClick={onIncrement} isDisabled={value > maxValue - step} />
    </div>
  );
};

const StepButton: React.FC<
  React.PropsWithChildren<{ label: string; isDisabled: boolean; onClick: () => void }>
> = ({ label, isDisabled, onClick }) => (
  <button
    className={clsx('btn-base bg-gray-200 text-[26px] px-4', isDisabled && 'opacity-50')}
    onClick={onClick}
  >
    {label}
  </button>
);

import { NotificationWithData } from '@koolumbus/shared/data';
import { getLocationName } from '@koolumbus/shared/utils';
import { useLocale } from '@koolumbus/web/utils';
import React, { useMemo } from 'react';
import {
  getNotificationsGroups,
  isAuctionEvent,
  isBookingEvent,
  NotificationGroup,
} from '../../notifications';
import { IconButton } from '../common';
import { Drawer } from '../common/Drawer';
import { MaterialIcon, MaterialIconName } from '../common/MaterialIcon';

interface NotificationsDrawerProps {
  items: NotificationWithData[];
  isOpen: boolean;
  onClose: () => void;
  onGroupClick: (item: NotificationGroup) => void;
  onGroupDismiss: (item: NotificationGroup) => void;
}

export const NotificationsDrawer: React.FC<React.PropsWithChildren<NotificationsDrawerProps>> = ({
  isOpen,
  onClose,
  items,
  onGroupClick,
  onGroupDismiss,
}) => {
  const groups = useMemo(() => getNotificationsGroups(items), [items]);

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="md" className="bg-gray-50">
      <div className="drawer-header">Notifications</div>

      <div>
        {items.length > 0 ? (
          <ul>
            {groups.map((group) => (
              <NotificationGroupTile
                key={`${group.event}~${group.resourceId}`}
                group={group}
                onClick={() => onGroupClick(group)}
                onDismiss={() => onGroupDismiss(group)}
              />
            ))}
          </ul>
        ) : (
          <p className="text-gray-400 text-lg p-5">There are no notifications for you right now</p>
        )}
      </div>
    </Drawer>
  );
};

interface NotificationGroupTileProps {
  group: NotificationGroup;
  onClick: () => void;
  onDismiss: () => void;
}

const NotificationGroupTile: React.FC<React.PropsWithChildren<NotificationGroupTileProps>> = ({
  group,
  onClick,
  onDismiss,
}) => {
  const locale = useLocale();
  const auction = group.resource as NotificationWithData['auction'];

  const text = useMemo(() => {
    const location = auction?.location ? getLocationName(auction.location, locale) : 'Unknown';

    switch (group.event) {
      case 'AUCTION_BID_ACCEPTED':
        return 'Your auction bid has been accepted!';
      case 'AUCTION_BID_REJECTED':
        return 'Your auction bid has been rejected';

      case 'AUCTION_EXPIRED':
        return `Your auction for an apartment in ${location} has expired`;

      case 'BOOKING_CONFIRMED':
        return 'Your booking has been confirmed by property owner';
      case 'BOOKING_REJECTED':
        return 'Your booking has been canceled by property owner';

      case 'NEW_AUCTION_BID':
        if (group.count > 1) {
          return `Your auction for an apartment in ${location} has received ${group.count} new bids!`;
        } else {
          return `Your auction for an apartment in ${location} has received a new bid!`;
        }

      case 'NEW_BOOKING':
        if (group.count > 1) {
          return `There are ${group.count} new bookings waiting for a response`;
        } else {
          return 'There is a new booking waiting for a response';
        }
    }
  }, [auction?.location, group.count, group.event, locale]);

  const icon = useMemo((): MaterialIconName => {
    if (isBookingEvent(group.event)) {
      return 'book';
    }

    if (isAuctionEvent(group.event)) {
      return 'card-travel';
    }

    return 'help';
  }, [group.event]);

  return (
    <div
      className="flex w-full items-center bg-white shadow-sm pl-6 pr-5 py-5 cursor-pointer hover:bg-gray-50"
      onClick={onClick}
    >
      <div className="flex flex-1 items-center">
        <div className="flex-center rounded-full min-w-[2.25rem] min-h-[2.25rem] bg-primary-100">
          <MaterialIcon className="text-xl text-primary" icon={icon} />
        </div>

        <p className="ml-4 text-base font-normal text-gray-600">{text}</p>
      </div>

      <IconButton
        variant="outline"
        size="sm"
        aria-label="dismiss"
        className="ml-3"
        icon={<MaterialIcon className="text-xl text-gray-500" icon="check" />}
        onClick={(e) => {
          e.stopPropagation();
          onDismiss();
        }}
      />
    </div>
  );
};

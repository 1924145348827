import clsx from 'clsx';

const classes = {
  sizes: {
    xs: 'spinner-xs',
    sm: 'spinner-sm',
    md: 'spinner-md',
    lg: 'spinner-lg',
    xl: 'spinner-xl',
  },
};

export type SpinnerSize = keyof (typeof classes)['sizes'];

export interface SpinnerProps {
  size?: SpinnerSize;
  className?: string;
}

export const Spinner: React.FC<React.PropsWithChildren<SpinnerProps>> = ({
  size = 'md',
  className,
}) => (
  <div
    className={clsx('spinner', classes.sizes[size], className)}
    style={{
      borderBottomColor: 'rgb(229, 231, 235)',
      borderLeftColor: 'rgb(229, 231, 235)',
      animationDuration: '650ms',
    }}
  >
    <span className="sr-only">Loading...</span>
  </div>
);

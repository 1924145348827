import { delay } from '@koolumbus/shared/utils';
import { createTxs } from '@koolumbus/web/utils';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { Dialog, DialogTitle, MaterialIcon, Spinner } from '../';

export interface ConfirmDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void | Promise<void>;
  title: string;
  body: string | React.ReactNode;
  variant?: 'default' | 'danger' | 'warning';
  confirmLabel?: string;
}

export const ConfirmDialog: React.FC<React.PropsWithChildren<ConfirmDialogProps>> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  body,
  variant = 'default',
  confirmLabel,
}) => {
  const tx = useTxs();
  const leastDestructiveRef = useRef(null);

  const [isLoading, setLoading] = useState(false);

  const handleConfirm = async () => {
    const res = onConfirm();
    if (res instanceof Promise) {
      setLoading(true);
      await res;
    }
    onClose();
    await delay(200);
    setLoading(false);
  };

  const isDefault = variant === 'default';
  const isDanger = variant === 'danger';
  const isWarning = variant === 'warning';

  return (
    <Dialog isOpen={isOpen} onClose={onClose} size="xs" closeBtn={false}>
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div
            className={clsx(
              'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10',
              {
                'bg-primary-100': isDefault,
                'bg-yellow-100': isWarning,
                'bg-red-100': isDanger,
              },
            )}
          >
            <DialogIcon variant={variant} />
          </div>

          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <DialogTitle as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {title}
            </DialogTitle>

            <div className="mt-2">
              {typeof body === 'string' ? (
                <p className="text-sm text-gray-500 whitespace-pre-wrap">{body}</p>
              ) : (
                body
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          disabled={isLoading}
          className={clsx(
            'w-full inline-flex justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base text-white sm:ml-3 sm:w-auto sm:text-sm',
            'focus:outline-none focus:ring-2 focus:ring-offset-2',
            {
              'bg-primary hover:bg-primary-600 focus:ring-primary': isDefault,
              'bg-yellow-600 hover:bg-yellow-600 focus:ring-yellow-600': isWarning,
              'bg-red-600 hover:bg-red-700 focus:ring-red-500': isDanger,
            },
          )}
          onClick={handleConfirm}
        >
          {isLoading && (
            <Spinner
              size="sm"
              className={clsx('mr-2.5', {
                'border-primary-400': isDefault,
                'border-orange-400': isWarning,
                'border-red-400': isDanger,
              })}
            />
          )}
          {confirmLabel || tx.confirm}
        </button>

        <button
          type="button"
          className={clsx(
            'mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base text-gray-700 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm',
            'focus:outline-none focus:ring-2 focus:ring-offset-2',
            {
              'focus:ring-primary': isDefault,
              'focus:ring-yellow-500': isWarning,
              'focus:ring-red-500': isDanger,
            },
          )}
          onClick={onClose}
          ref={leastDestructiveRef}
        >
          {tx.cancel}
        </button>
      </div>
    </Dialog>
  );
};

const DialogIcon: React.FC<React.PropsWithChildren<{ variant: ConfirmDialogProps['variant'] }>> = ({
  variant = 'default',
}) => {
  switch (variant) {
    case 'danger':
      return (
        <MaterialIcon
          icon="error-outline"
          className="text-red-600 text-[26px]"
          aria-hidden="true"
        />
      );
    case 'warning':
      return (
        <MaterialIcon
          icon="error-outline"
          className="text-yellow-600 text-[26px]"
          aria-hidden="true"
        />
      );
    case 'default':
      return <MaterialIcon icon="info" className="text-primary text-[26px]" aria-hidden="true" />;
  }
};

const useTxs = createTxs({
  en: { cancel: 'Cancel', confirm: 'Confirm' },
  it: { cancel: 'Annulla', confirm: 'Conferma' },
});

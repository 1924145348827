export const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};

export const device = {
  // devices
  tablet: `(min-width: ${breakpoints.sm}px)`,
  desktop: `(min-width: ${breakpoints.lg}px)`,
  // sizes
  sm: `(min-width: ${breakpoints.sm}px)`, // tablet
  md: `(min-width: ${breakpoints.md}px)`,
  lg: `(min-width: ${breakpoints.lg}px)`, // desktop
  xl: `(min-width: ${breakpoints.xl}px)`,
  '2xl': `(min-width: ${breakpoints['2xl']}px)`,
};

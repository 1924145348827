import { BookingStatus } from '@prisma/client';
import clsx from 'clsx';
import React from 'react';

interface BookingStatusTagProps {
  status: BookingStatus;
}

const getColorClass = (status: BookingStatus) => {
  switch (status) {
    case 'CONFIRMED':
      return 'badge-green';
    case 'REJECTED':
      return 'badge-red';
    case 'CANCELLED':
      return 'badge-orange';
    case 'PENDING':
    default:
      return '';
  }
};

export const BookingStatusTag: React.FC<React.PropsWithChildren<BookingStatusTagProps>> = ({
  status,
}) => {
  return (
    <span className={clsx('badge font-semibold text-xs', getColorClass(status))}>{status}</span>
  );
};

// const useTxs = createTxs({
//   en: {
//     BLOCKED: 'BLOCKED',
//     PENDING: 'PENDING',
//     CANCELLED: 'CANCELLED',
//     CONFIRMED: 'CONFIRMED',
//     REJECTED: 'REJECTED',
//   },
//   it: {
//     BLOCKED: 'BLOCCATA',
//     PENDING: 'PENDING',
//     CANCELLED: 'CANCELLATA',
//     CONFIRMED: 'CONFERMATA',
//     REJECTED: 'RIFIUTATA',
//   },
// });

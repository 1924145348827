import { Dialog as HDialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment, PropsWithChildren, useRef } from 'react';
import { MaterialIcon } from './MaterialIcon';

export const DialogTitle = HDialog.Title;

const sizes = {
  xs: 'dialog-xs',
  sm: 'dialog-sm',
  md: 'dialog-md',
  lg: 'dialog-lg',
  xl: 'dialog-xl',
  full: 'dialog-full',
};

export type DialogSize = keyof typeof sizes;

export interface DialogProps {
  children: React.ReactNode;
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  size?: DialogSize;
  initialFocusRef?: React.MutableRefObject<HTMLElement | null>;
  closeBtn?: boolean;
  className?: string;
}

export const Dialog = ({
  id,
  isOpen,
  onClose,
  initialFocusRef,
  closeBtn = true,
  size = 'md',
  className,
  children,
}: PropsWithChildren<DialogProps>) => {
  const closeBtnRef = useRef(null);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <HDialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={initialFocusRef ?? closeBtnRef}
        onClose={onClose}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <HDialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              id={id}
              className={clsx(
                'inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all min-w-full',
                'sm:min-w-min sm:my-8 sm:align-middle sm:w-full',
                sizes[size],
                className,
              )}
            >
              {closeBtn && (
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="flex-center bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                    onClick={onClose}
                    ref={closeBtnRef}
                  >
                    <span className="sr-only">Close</span>
                    <MaterialIcon className="icon-md" icon="clear" aria-hidden="true" />
                  </button>
                </div>
              )}

              {children}
            </div>
          </Transition.Child>
        </div>
      </HDialog>
    </Transition.Root>
  );
};

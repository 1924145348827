import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment, PropsWithChildren } from 'react';
import { MaterialIcon } from './MaterialIcon';

const sizes = {
  xs: 'drawer-xs',
  sm: 'drawer-sm',
  md: 'drawer-md',
  lg: 'drawer-lg',
  xl: 'drawer-xl',
  '2xl': 'drawer-2xl',
  full: 'drawer-full',
};

export type DrawerSize = keyof typeof sizes;

export interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  size?: DrawerSize;
  className?: string;
  placement?: 'right' | 'left';
  closeButton?: boolean;
}

export const Drawer: React.FC<PropsWithChildren<DrawerProps>> = ({
  isOpen,
  onClose,
  size = 'sm',
  className,
  children,
  placement = 'right',
  closeButton = true,
}: PropsWithChildren<DrawerProps>) => {
  const isLeft = placement === 'left';

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-hidden" onClose={onClose}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div
            className={clsx('fixed inset-y-0 max-w-full flex', {
              'right-0': !isLeft,
              'left-0': isLeft,
            })}
          >
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300 sm:duration-400"
              enterFrom={isLeft ? '-translate-x-full' : 'translate-x-full'}
              enterTo={isLeft ? 'translate-x-0' : 'translate-x-0'}
              leave="transform transition ease-in-out duration-300 sm:duration-400"
              leaveFrom={isLeft ? 'translate-x-0' : 'translate-x-0'}
              leaveTo={isLeft ? '-translate-x-full' : 'translate-x-full'}
            >
              <div className={clsx('drawer-base', sizes[size], className)}>
                <div className="h-full flex flex-col shadow-xl overflow-y-auto">
                  {closeButton && (
                    <button
                      type="button"
                      className="absolute flex-center right-4 top-[14px] bg-gray-200 p-1 rounded-md text-gray-500 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                      onClick={onClose}
                    >
                      <span className="sr-only">Close panel</span>
                      <MaterialIcon className="icon-md" icon="clear" aria-hidden="true" />
                    </button>
                  )}

                  {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

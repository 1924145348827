import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

export const useSafeLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export const useDisclosure = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState);

  const onClose = useCallback(() => setIsOpen(false), []);
  const onOpen = useCallback(() => setIsOpen(true), []);
  const onToggle = useCallback(() => setIsOpen((v) => !v), []);

  return {
    isOpen,
    onClose,
    onOpen,
    onToggle,
  };
};

export const useIsMounted = (delay = 0) => {
  const [mounted, setMounted] = useState(false);

  useLayoutEffect(() => {
    if (delay > 0) {
      setTimeout(() => setMounted(true), delay);
    } else {
      setMounted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return mounted;
};

export type UseMediaQueryParams<V> = {
  base: V;
  sm?: V;
  md?: V;
  lg?: V;
  xl?: V;
  '2xl'?: V;
};

// export function useMediaQuery<V>(params: UseMediaQueryParams<V>): V {
//   const [matches, setMatches] = useState(false);

//   useEffect(() => {
//     const media = window.matchMedia(query);

//     if (media.matches !== matches) {
//       setMatches(media.matches);
//     }

//     const listener = () => {
//       setMatches(media.matches);
//     };

//     media.addListener(listener);

//     return () => media.removeListener(listener);
//   }, [matches]);

//   return matches ? ;
// }

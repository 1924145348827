import clsx from 'clsx';
import React from 'react';
import { MaterialIcon, MaterialIconName } from './MaterialIcon';

export type AlertVariant = 'info' | 'success' | 'warning' | 'danger';

export interface AlertProps {
  variant: AlertVariant;
  title: string;
  body?: React.ReactNode;
  actions?: React.ReactNode;
  className?: string;
}

const colors = {
  root: {
    info: 'bg-blue-50',
    success: 'bg-green-50',
    warning: 'bg-yellow-50',
    danger: 'bg-red-50',
  },
  title: {
    info: 'text-gray-600',
    success: 'text-green-800',
    warning: 'text-yellow-800',
    danger: 'text-red-800',
  },
  body: {
    info: 'text-gray-500',
    success: 'text-green-700',
    warning: 'text-yellow-700',
    danger: 'text-red-700',
  },
};

const icons: Record<AlertVariant, MaterialIconName> = {
  info: 'info',
  warning: 'error',
  danger: 'error',
  success: 'check-circle',
};

const iconColors: Record<AlertVariant, string> = {
  info: 'text-blue-400',
  success: 'text-green-400',
  warning: 'text-yellow-400',
  danger: 'text-red-400',
};

export const Alert: React.FC<React.PropsWithChildren<AlertProps>> = ({
  variant,
  title,
  body,
  actions,
  className,
}) => {
  return (
    <div className={clsx('rounded-md p-4', colors.root[variant], className)}>
      <div className="flex">
        <MaterialIcon
          icon={icons[variant]}
          className={clsx(iconColors[variant], 'icon-lg')}
          aria-hidden={true}
        />

        <div className="ml-3">
          <p className={`text-base font-medium ${colors.title[variant]}`}>{title}</p>

          {body && (
            <div className={`mt-2 text-sm ${colors.body[variant]}`}>
              {typeof body === 'string' ? <p>{body}</p> : body}
            </div>
          )}

          {actions}
        </div>
      </div>
    </div>
  );
};

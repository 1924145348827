import { useSafeLayoutEffect } from '@koolumbus/web/ui';
import { useState } from 'react';

const useHasScrolled = (offset = 0) => {
  const [scrolled, setScrolled] = useState(false);

  useSafeLayoutEffect(() => {
    const onScroll = () => {
      if (scrolled && window.scrollY <= offset) {
        setScrolled(false);
      }

      if (!scrolled && window.scrollY > offset) {
        setScrolled(true);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });

  return scrolled;
};

export default useHasScrolled;

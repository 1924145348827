import type { TippyProps } from '@tippyjs/react';
import Tippy from '@tippyjs/react';

type TooltipProps = {
  label: React.ReactNode;
  children: TippyProps['children'];
};

export const Tooltip = ({ label, children }: TooltipProps) => {
  return (
    <Tippy content={label} arrow={false} className="rounded-md bg-blackAlpha-800">
      {children}
    </Tippy>
  );
};

import clsx from 'clsx';
import { useRouter } from 'next/router';
import { PageWithLayout } from '../../lib/types/page';
import Footer from './Footer/Footer';
import Header from './Header/Header';

interface MainLayoutProps {
  footer?: boolean;
  headerPx?: boolean;
  bg?: 'white' | 'gray';
}

const MainLayout: React.FC<React.PropsWithChildren<MainLayoutProps>> = ({
  children,
  headerPx = true,
  footer = true,
  bg = 'gray',
}) => {
  const isHomePage = useRouter().pathname === '/';

  return (
    <div className="flex flex-col">
      <Header px={headerPx} />

      <main
        className={clsx(
          `min-h-screen`,
          isHomePage ? 'mt-0' : 'mt-14 sm:mt-16 md:mt-[72px]',
          bg === 'gray' ? 'bg-gray-50' : 'bg-white',
        )}
      >
        {children}
      </main>

      {footer && <Footer />}
    </div>
  );
};

export const getMainLayout: (props?: MainLayoutProps) => PageWithLayout['getLayout'] = (
  props = {},
) => {
  return (page) => <MainLayout {...props}>{page}</MainLayout>;
};

export default MainLayout;

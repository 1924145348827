import { HeaderButton } from '@koolumbus/web/ui';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useAuthCtx } from '../../lib/context/auth.context';
import { fbStore } from '../../lib/services/firebase-client';
import { useFireChatsStore } from '../../lib/state/fire-chats.store';

interface InboxHeaderButtonProps {
  isFloating: boolean;
}

const InboxHeaderButton: React.FC<React.PropsWithChildren<InboxHeaderButtonProps>> = ({
  isFloating,
}) => {
  const {
    state: { user },
  } = useAuthCtx();
  const router = useRouter();

  const { chatsToRead, setChatsToRead } = useFireChatsStore();
  const hasUnreadMessages = chatsToRead.length > 0;

  useEffect(() => {
    router.prefetch('/me/inbox');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let unsubscribe: (() => void) | null = null;

    if (user?.id) {
      const snapQuery = query(
        collection(fbStore, 'chats'),
        where('userId', '==', user.id),
        where('userHasUnreadMessages', '==', true),
      );

      unsubscribe = onSnapshot(snapQuery, (snap) => {
        setChatsToRead(snap.docs.map((doc) => doc.id));
      });
    }

    return () => unsubscribe?.();
  }, [setChatsToRead, user?.id]);

  const onClick = () => {
    router.push(`/me/inbox`);
  };

  return (
    <HeaderButton
      icon="message"
      tooltip="Inbox"
      isFloating={isFloating}
      onClick={onClick}
      hasRedDot={hasUnreadMessages}
    />
  );
};

export default InboxHeaderButton;

import { useToast } from '@koolumbus/web/ui';
import React from 'react';
import { useAuthCtx } from '../../lib/context/auth.context';

interface SocialLoginProps {}

const SocialLogin: React.FC<React.PropsWithChildren<SocialLoginProps>> = () => {
  const auth = useAuthCtx();
  const toast = useToast();

  const onGoogleSignIn = async () => {
    try {
      // TODO: handle error
      await auth.signInWithGoogle();
    } catch (err) {
      console.error(err);
    }
  };

  const onFacebookSignIn = () => {
    // TODO: handle facebook sign in
    toast.info('COMING SOON!');
  };

  return (
    <div className="mt-2 grid grid-cols-2 gap-3">
      <div>
        <button
          onClick={onGoogleSignIn}
          className="w-full h-11 inline-flex justify-center items-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        >
          <span className="sr-only">Sign in with Google</span>
          <GoogleIcon />
        </button>
      </div>

      <div>
        <button
          onClick={onFacebookSignIn}
          className="w-full h-11 inline-flex justify-center items-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        >
          <span className="sr-only">Sign in with Facebook</span>
          <FacebookIcon />
        </button>
      </div>
    </div>
  );

  // return (
  //   <div className="flex flex-col space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0 w-full">
  //     <button
  //       className="rounded-md flex-center text-white bg-[#dd4b39] relative py-3 w-full"
  //       onClick={onGoogleSignIn}
  //     >
  //       <span className="absolute left-4">
  //         <GoogleIcon />
  //       </span>
  //       <span className="flex-1">Google</span>
  //     </button>

  //     <button
  //       className="rounded-md flex-center text-white bg-[#3b5998] relative py-3 w-full"
  //       onClick={onFacebookSignIn}
  //     >
  //       <span className="absolute left-4">
  //         <FacebookIcon />
  //       </span>
  //       <span className="flex-1">Facebook</span>
  //     </button>
  //   </div>
  // );
};

const GoogleIcon = () => (
  <svg
    className="w-5 h-5"
    aria-hidden="true"
    fill="currentColor"
    stroke="currentColor"
    strokeWidth="0"
    viewBox="0 0 488 512"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path>
  </svg>
);

const FacebookIcon = () => (
  <svg
    className="w-5 h-5"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 512 512"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path>
  </svg>
);

export default SocialLogin;

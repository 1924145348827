import { Dialog, useToast } from '@koolumbus/web/ui';
import React, { useEffect } from 'react';
import { useAuthCtx } from '../../../lib/context/auth.context';
import AuthForm from '../../auth/AuthForm';

interface AuthModalProps {
  type?: 'login' | 'signup';
  isOpen: boolean;
  onClose: () => void;
  onLoggedIn?: () => void;
}

const AuthModal: React.FC<React.PropsWithChildren<AuthModalProps>> = ({
  type: initialType,
  isOpen,
  onClose,
  onLoggedIn,
}) => {
  const {
    state: { status: authStatus },
  } = useAuthCtx();
  const toast = useToast();

  useEffect(() => {
    if (isOpen && authStatus === 'loggedIn') {
      onClose();
      toast.success('Welcome!');
      if (typeof onLoggedIn !== 'undefined') {
        onLoggedIn();
      }
    }
  }, [authStatus, isOpen, onClose, onLoggedIn, toast]);

  return (
    <Dialog isOpen={isOpen} onClose={onClose} className="max-w-md">
      <div className="bg-white px-6 sm:px-4 pt-2 sm:pt-4 pb-8 sm:pb-10">
        <AuthForm initialType={initialType} />
      </div>
    </Dialog>
  );
};

export default AuthModal;

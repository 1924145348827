import { MaterialIcon, useToast } from '@koolumbus/web/ui';
import { createTxs } from '@koolumbus/web/utils';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuthCtx } from '../../lib/context/auth.context';

interface SignUpFormValues {
  email: string;
  username: string;
  password: string;
  confirmPassword: string;
}

const SignUpForm: React.FC<React.PropsWithChildren<unknown>> = () => {
  const toast = useToast();
  const auth = useAuthCtx();
  const tx = useTxs();

  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    formState: { isSubmitting },
    getValues,
  } = useForm<SignUpFormValues>({
    mode: 'onTouched',
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await auth.signUp({ ...data, name: data.username });
    } catch (err) {
      console.error(err);
      toast.error();
    }
  });

  // const validateUsername = (value: string): string | boolean => {
  //   if (!value) {
  //     return 'Please provide a username';
  //   }

  //   if (value.length < 6) {
  //     return 'The username must be at least 6 characters longs';
  //   }

  //   return true;
  // };

  const validateEmail = (value: string): string | boolean => {
    if (!value) {
      return 'Please provide an email';
    }

    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (!emailRegex.test(value)) {
      return 'Please provide a valid email';
    }

    return true;
  };

  const validatePassword = (value: string): string | boolean => {
    if (!value) {
      return 'Please provide a password';
    }

    if (value.length < 6) {
      return 'Password must be at least 6 characters';
    }

    return true;
  };

  const validateConfirmPassword = (value: string): string | boolean => {
    if (value !== getValues().password) {
      return 'Passwords do not match';
    }

    return true;
  };

  const onTogglePswVisibility: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPassword((v) => !v);
  };

  return (
    <form onSubmit={onSubmit} className="space-y-6">
      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          {tx.email}
        </label>
        <div className="mt-1">
          <input
            id="email"
            type="email"
            autoComplete="email"
            className="text-input"
            aria-describedby="email-error"
            data-valid={!errors.email}
            {...register('email', { validate: validateEmail })}
          />

          {errors.email && (
            <p className="input-error" id="email-error">
              {errors.email.message}
            </p>
          )}
        </div>
      </div>

      <div className="space-y-1">
        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
          Password
        </label>
        <div className="relative mt-1">
          <input
            id="password"
            type={showPassword ? 'text' : 'password'}
            autoComplete="current-password"
            className="text-input pr-14"
            data-valid={!errors.password}
            {...register('password', { validate: validatePassword })}
          />

          <div className="absolute inset-y-0 right-0 pr-1.5 flex items-center">
            <button
              className="flex-center bg-gray-100 h-9 w-9 rounded-md"
              onClick={onTogglePswVisibility}
              aria-label="Show or hide password"
            >
              <MaterialIcon
                icon={showPassword ? 'visibility-off' : 'visibility'}
                variant="outlined"
                className="text-gray-500 text-xl"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>

        {errors.password && (
          <p className="input-error" id="email-error">
            {errors.password.message}
          </p>
        )}
      </div>

      <div className="space-y-1">
        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
          {tx.confirmPassword}
        </label>
        <div className="mt-1">
          <input
            id="confirmPassword"
            type={showPassword ? 'text' : 'password'}
            className="text-input"
            data-valid={!errors.confirmPassword}
            aria-describedby="confirmPassword-error"
            {...register('confirmPassword', { validate: validateConfirmPassword })}
          />

          {errors.confirmPassword && (
            <p className="input-error" id="confirmPassword-error">
              {errors.confirmPassword.message}
            </p>
          )}
        </div>
      </div>

      {/* TODO: real terms and conditions ( and prevent submitting if not accepted! ) */}

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <input
            id="remember-me"
            name="remember-me"
            type="checkbox"
            className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
          />
          <label htmlFor="accept-terms" className="ml-2 block text-sm text-gray-900">
            {tx.agreeWithTerms()}
          </label>
        </div>
      </div>

      <button
        type="submit"
        disabled={isSubmitting}
        className="w-full h-11 flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm font-medium text-white bg-primary hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-60"
      >
        {tx.signUp}
      </button>
    </form>
  );
};

const useTxs = createTxs({
  en: {
    email: 'Email address',
    firstAndLastName: 'First and last name',
    // TODO: stuff
    // provideName: 'Please provide a valid username',
    // provideEmail: 'Please provide a valid email address',
    // providePassword: 'Please provide a password',
    confirmPassword: 'Confirm password',
    signUp: 'Sign Up',
    agreeWithTerms: () => (
      <>
        I agree with
        {/* TODO: real terms */}
        <a href="/terms" target="_blank" className="text-primary underline ml-1">
          terms and conditions
        </a>
      </>
    ),
  },
  it: {
    email: 'Indirizzo email',
    firstAndLastName: 'Nome e cognome',
    confirmPassword: 'Conferma password',
    signUp: 'Registrati',
    agreeWithTerms: () => (
      <>
        Accetto i{/* TODO: real terms */}
        <a href="/terms" target="_blank" className="text-primary underline ml-1">
          termini e condizioni
        </a>
      </>
    ),
  },
});

export default SignUpForm;

import { Locale } from '@koolumbus/shared/config';
import { MaterialIconName } from '@koolumbus/web/ui';

export interface MenuItemProps {
  label: { [key in Locale]: string };
  icon: MaterialIconName;
  href: string;
}

export const primaryMenuItems: MenuItemProps[] = [
  { label: { en: 'Auctions', it: 'Le mie aste' }, href: '/me/auctions', icon: 'card-travel' },
  { label: { en: 'Favorites', it: 'Preferiti' }, href: '/me/favorites', icon: 'favorite-border' },
  { label: { en: 'Bookings', it: 'Prenotazioni' }, href: '/me/bookings', icon: 'book' },
  { label: { en: 'Inbox', it: 'Messaggi' }, href: '/me/inbox', icon: 'message' },
];

export const secondaryMenuItems: MenuItemProps[] = [
  { label: { en: 'Settings', it: 'Impostazioni' }, href: '/me/settings', icon: 'settings' },
];

import clsx from 'clsx';

const sizes = {
  xs: 'w-6 h-6',
  sm: 'w-8 h-8',
  md: 'w-10 h-10',
  lg: 'w-12 h-12',
  xl: 'w-14 h-14',
};

export interface AvatarProps {
  size?: keyof typeof sizes;
  src?: string | null;
  className?: string;
}

export const Avatar: React.FC<React.PropsWithChildren<AvatarProps>> = ({
  size = 'md',
  src,
  className,
}) => {
  if (src) {
    return (
      <img
        src={src}
        className={clsx('inline-block rounded-full', sizes[size], className)}
        alt="avatar"
      />
    );
  }

  return (
    <span
      className={clsx(
        'inline-block rounded-full overflow-hidden bg-gray-100',
        sizes[size],
        className,
      )}
    >
      <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg>
    </span>
  );
};

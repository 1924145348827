import { Switch as HSwitch } from '@headlessui/react';
import clsx from 'clsx';
import React from 'react';

const sizes = {
  sm: {
    wrapper: 'h-5 w-10',
    knob: 'h-4 w-4',
  },
  md: {
    wrapper: 'h-6 w-11',
    knob: 'h-5 w-5',
  },
};

export interface SwitchProps {
  id?: string;
  size?: keyof typeof sizes;
  isChecked: boolean;
  onChange: (value: boolean) => void;
  className?: string;
}

export const Switch = React.forwardRef<HTMLButtonElement, SwitchProps>(
  ({ isChecked, onChange, className, size = 'md', ...props }, ref) => {
    return (
      <HSwitch
        {...props}
        ref={ref}
        checked={isChecked}
        onChange={onChange}
        className={clsx(
          isChecked ? 'bg-primary' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary',
          sizes[size].wrapper,
          className,
        )}
      >
        <span
          aria-hidden="true"
          className={clsx(
            isChecked ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
            sizes[size].knob,
          )}
        />
      </HSwitch>
    );
  },
);

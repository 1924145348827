import clsx from 'clsx';
import React from 'react';
import ReactSlider from 'react-slider';

interface RangeSliderProps {
  value: [number, number];
  onChange: (value: [number, number]) => void;
  min?: number;
  max?: number;
  step?: number;
}

export const RangeSlider: React.FC<React.PropsWithChildren<RangeSliderProps>> = ({
  value,
  onChange: handleChange,
  min = 0,
  max = 1000,
  step = 10,
}) => {
  const onChange = (value: number | readonly number[]) => {
    if (value && Array.isArray(value)) {
      handleChange(value as [number, number]);
    }
  };

  return (
    <ReactSlider
      className="w-full h-1.5"
      value={value}
      onChange={onChange}
      min={min}
      max={max}
      step={step}
      renderTrack={(props, state) => (
        <div
          {...props}
          className={clsx(
            'top-0 bottom-0 rounded-full',
            state.index === 1 ? 'bg-primary-500' : 'bg-[#ddd]',
          )}
        />
      )}
      renderThumb={(props) => (
        <div
          {...props}
          className="absolute top-[-8px] w-5 h-5 bg-white shadow-md rounded-full cursor-[grab] border border-gray-300"
        />
      )}
    />
  );
};

import { ChatData, ChatMessage } from '@koolumbus/shared/data';
import { date } from '@koolumbus/shared/utils';
import { config } from '@koolumbus/web/config';
import { getPropertyImageURL } from '@koolumbus/web/utils';
import clsx from 'clsx';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, DatesSeparator, Drawer } from '../common';

interface ChatDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  chat: ChatData | null;
  messages: ChatMessage[];
  onSend: (text: string) => Promise<void>;
  isSendLoading: boolean;
  onOpenProperty: () => void;
  isPMS: boolean;
}

export const ChatDrawer: React.FC<React.PropsWithChildren<ChatDrawerProps>> = ({
  isOpen,
  onClose,
  title,
  chat,
  messages,
  onSend: handleSend,
  isSendLoading,
  onOpenProperty,
  isPMS,
}) => {
  const messagesBottomDivRef = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const updateScroll = (behavior: ScrollOptions['behavior']) => {
      if (messagesBottomDivRef.current) {
        messagesBottomDivRef.current.scrollIntoView({ behavior });
      }
    };

    updateScroll(messages.length ? 'smooth' : undefined);
  }, [messages]);

  const onSend = async () => {
    try {
      await handleSend(inputValue);
      setInputValue('');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="lg">
      <div className="p-4 text-xl font-medium border-b bg-white">{title}</div>

      <div className="min-h-0 flex-1 flex flex-col overflow-y-auto overflow-x-hidden bg-gray-50">
        {chat !== null && (
          <ChatDrawerFloatingBooking bookingData={chat.booking} onOpenProperty={onOpenProperty} />
        )}

        {chat !== null && (
          <div className="w-full h-[calc(100%-5rem)] overflow-y-auto">
            <div className="py-5 px-6">
              {messages.map((msg) => (
                <MessageTile
                  key={msg.id}
                  message={msg}
                  isOnRightSide={isPMS ? msg.sentBy !== chat.userId : msg.sentBy === chat.userId}
                />
              ))}
              <div ref={messagesBottomDivRef} />
            </div>
          </div>
        )}
      </div>

      <div className="flex-shrink-0 px-4 py-4 flex justify-end border-t">
        <div className="flex flex-col w-full">
          <textarea
            className="textarea mb-2 py-2 px-3 border-gray-300"
            value={inputValue}
            disabled={isSendLoading}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Write a message"
            rows={4}
            maxLength={1000}
          />

          <div className="flex justify-end mt-2">
            {/* TODO: report */}
            {/* <IconButton
                  aria-label="report"
                  variant="outline"
                  icon={<MaterialIcon icon="report" />}
                /> */}

            <div className="flex justify-end space-x-2">
              <Button variant="outline" onClick={onClose}>
                Close
              </Button>

              <Button onClick={onSend} isLoading={isSendLoading} isDisabled={!inputValue.length}>
                Send
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

interface ChatDrawerFloatingBookingProps {
  bookingData: ChatData['booking'];
  onOpenProperty: () => void;
}

export const ChatDrawerFloatingBooking: React.FC<
  React.PropsWithChildren<ChatDrawerFloatingBookingProps>
> = ({ bookingData: booking, onOpenProperty }) => {
  const detailsSeparatorDot = <span className="text-gray-400 mx-2">{'\u2022'}</span>;

  const duration = useMemo(() => {
    const days = booking.duration;

    if (days <= 29) {
      return `${booking.duration} day${days > 1 ? 's' : ''}`;
    }

    const months = date(booking.checkOut).diff(booking.checkIn, 'months');

    return `${months} month${months > 1 ? 's' : ''}`;
  }, [booking.checkIn, booking.checkOut, booking.duration]);

  const getFirstImgUrl = (): string => {
    const images = Array.from(booking.property.images);

    if (config.env === 'development' && !images.length) {
      return `/assets/images/post-image-1.jpg`;
    }

    images.sort((a, b) => (a.index < b.index ? -1 : 1));
    return getPropertyImageURL(config.env, booking.tenantId, images[0].id);
  };

  return (
    <div
      className="flex h-20 shadow-sm bg-white px-5 py-4 cursor-pointer hover:bg-gray-50"
      onClick={onOpenProperty}
    >
      <div className="flex min-w-[80px] max-w-[80px]">
        <img
          className="rounded-md w-full max-h-full object-cover"
          src={getFirstImgUrl()}
          alt="property"
        />
      </div>

      <div className="flex flex-1 flex-col items-start ml-4 md:ml-5">
        <p>{booking.property.name}</p>

        <div className="flex w-full truncate font-light text-gray-500 text-sm items-center min-w-0">
          {date(booking.checkIn).format('DD MMM, YY')}

          <div className="mx-2">
            <DatesSeparator />
          </div>

          {date(booking.checkOut).format('DD MMM, YY')}

          {detailsSeparatorDot}

          <p>{duration}</p>
        </div>
      </div>
    </div>
  );
};

interface MessageTileProps {
  message: ChatMessage;
  isOnRightSide: boolean;
}

export const MessageTile: React.FC<React.PropsWithChildren<MessageTileProps>> = ({
  message,
  isOnRightSide,
}) => {
  return (
    <div className={clsx('flex mt-3', isOnRightSide ? 'justify-end' : 'justify-start')}>
      <div
        className={clsx(
          'px-4 py-2 rounded-md',
          'max-w-[86%] sm:max-w-[80%] md:max-w-[70%]',
          !isOnRightSide ? 'bg-blackAlpha-50 text-gray-900' : 'bg-primary text-white',
        )}
      >
        <p>{message.text}</p>
      </div>
    </div>
  );
};

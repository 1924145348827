import clsx from 'clsx';
import React from 'react';
import { MaterialIcon, MaterialIconName } from '../common/MaterialIcon';

interface HeaderButtonProps {
  icon: MaterialIconName;
  tooltip: string;
  isFloating: boolean;
  onClick?: () => void;
  hasRedDot?: boolean;
}

export const HeaderButton = React.forwardRef<HTMLDivElement, HeaderButtonProps>(
  ({ icon, tooltip, isFloating, onClick, hasRedDot }, ref) => {
    return (
      <div className="relative" ref={ref}>
        <button
          onClick={onClick}
          aria-label={tooltip}
          className={clsx(
            'flex justify-center items-center w-10 h-10 rounded-md',
            isFloating ? 'text-white' : 'text-gray-400',
            isFloating
              ? 'hover:bg-white hover:bg-opacity-25'
              : 'hover:bg-blackAlpha-100 active:bg-blackAlpha-100',
          )}
        >
          <MaterialIcon variant="outlined" className="icon-md" icon={icon} />
        </button>

        <div
          className={clsx(
            !hasRedDot && 'hidden',
            'w-2.5 h-2.5 absolute top-2 right-2 bg-red-500 rounded-lg shadow-sm',
          )}
        />
      </div>
    );
  },
);

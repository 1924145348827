import { HeaderButton, NotificationGroup, NotificationsDrawer } from '@koolumbus/web/ui';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { trpc } from '../../../lib/trpc';

interface NotificationsButtonProps {
  isFloating: boolean;
}

const NotificationsButton: React.FC<React.PropsWithChildren<NotificationsButtonProps>> = ({
  isFloating,
}) => {
  const router = useRouter();
  const trpcCtx = trpc.useUtils();
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const { data } = trpc.notifications.list.useQuery({});
  const items = data?.items ?? [];

  const { mutateAsync: performRead, isLoading } = trpc.notifications.read.useMutation({
    onSuccess: () => {
      trpcCtx.notifications.list.invalidate();
    },
  });

  const onItemDismiss = async (item: NotificationGroup) => {
    if (isLoading) return;

    try {
      await performRead({ ids: item.ids });
    } catch (err) {
      console.error(err);
    }
  };

  const onItemClick = async (item: NotificationGroup) => {
    if (isLoading) return;

    setDrawerOpen(false);

    await onItemDismiss(item);

    switch (item.event) {
      case 'NEW_AUCTION_BID':
        return router.push(`/me/auctions/${item.resourceId}`);

      case 'BOOKING_CONFIRMED':
      case 'BOOKING_REJECTED':
        return router.push(`/me/bookings`);
    }
  };

  return (
    <>
      <HeaderButton
        icon="notifications"
        tooltip="Notifications"
        isFloating={isFloating}
        onClick={() => setDrawerOpen(true)}
        hasRedDot={Boolean(items.length)}
      />

      <NotificationsDrawer
        items={items}
        isOpen={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        onGroupClick={onItemClick}
        onGroupDismiss={onItemDismiss}
      />
    </>
  );
};

export default NotificationsButton;

import { Transition } from '@headlessui/react';
import { delay } from '@koolumbus/shared/utils';
import { LanguageMenuButton, MaterialIcon } from '@koolumbus/web/ui';
import { createTxs } from '@koolumbus/web/utils';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useAuthCtx } from '../../../lib/context/auth.context';
import useHasScrolled from '../../../lib/hooks/useHasScrolled';
import AuthModal from '../../common/auth-modal/AuthModal';
import InboxHeaderButton from '../../inbox/InboxHeaderButton';
import MobileDrawer from './MobileDrawer';
import NotificationsButton from './NotificationsButton';
import ProfileMenu from './ProfileMenu';

interface HeaderProps {
  px?: boolean;
}

const Header: React.FC<React.PropsWithChildren<HeaderProps>> = ({ px = true }) => {
  const router = useRouter();
  const {
    state: { status: authStatus },
  } = useAuthCtx();
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const [isSignInModalOpen, setSignInModalOpen] = useState(false);
  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);

  const hasScrolled = useHasScrolled();
  const isHomePage = router.pathname === '/';
  const isTransparent = isHomePage && !hasScrolled;

  useEffect(() => {
    // this whole effect is to prevent a weird headless-ui bug: on mobile, when the auth modal
    // is closed, it becomes impossible to scroll the whole page.
    // This is because when a dialog is opened headless-ui set overflow-hidden on the html root elem,
    // and reset it when it's closed. Since both the auth modal and the mobile drawer are dialogs,
    // things somehow conflict and overflow-hidden is never removed, so we remove it manually.

    const resetHtmlStyle = async () => {
      await delay(250);
      document.documentElement.style.overflow = 'auto';
      document.documentElement.style.paddingRight = 'auto';
    };

    if (!isSignInModalOpen && !isSignUpModalOpen) {
      resetHtmlStyle();
    }
  }, [isSignInModalOpen, isSignUpModalOpen]);

  const onSignIn = () => {
    setDrawerOpen(false);
    setSignInModalOpen(true);
  };

  const onSignUp = () => {
    setDrawerOpen(false);
    setSignUpModalOpen(true);
  };

  return (
    <header
      className={clsx('fixed top-0 w-screen z-30 transition-all', !isTransparent && 'bg-white')}
    >
      {!isTransparent && (
        <div className="absolute inset-0 shadow z-20 pointer-events-none" aria-hidden="true" />
      )}

      <div className="relative z-20">
        <div
          className={clsx(
            'flex justify-between items-center px-4 py-2 sm:py-3 md:py-4 sm:px-6 lg:px-8 md:space-x-10',
            px && 'max-w-7xl mx-auto',
          )}
        >
          {/* LOGO */}

          <div>
            <Link href="/" className="flex items-center">
              <span className="sr-only">Koolumbus</span>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img className="h-8 w-auto sm:h-10" src="/assets/images/logo/logo.png" alt="" />
              <h1
                className={clsx(
                  'hidden sm:block font-bold text-2xl ml-3',
                  !isTransparent ? 'text-primary' : 'text-white',
                )}
              >
                Koolumbus
              </h1>
            </Link>
          </div>

          {/* MOBILE */}

          <div className="flex md:hidden items-center">
            {authStatus === 'loggedIn' && (
              <Transition
                appear={true}
                show={true}
                enter="transition-all duration-200 delay-200"
                enterFrom="opacity-0 translate-y-5"
                enterTo="opacity-100 translate-y-0"
                leave="transition-all duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-5"
              >
                <div className="flex space-x-2 mr-7">
                  <InboxHeaderButton isFloating={isTransparent} />
                  <NotificationsButton isFloating={isTransparent} />
                  <LanguageMenuButton isFloating={isTransparent} />
                </div>
              </Transition>
            )}

            <button
              aria-label="menu"
              className={clsx(
                'btn-base icon-btn-md',
                'hover:bg-blackAlpha-100 active:bg-blackAlpha-100',
                'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
                isTransparent ? 'text-white' : 'text-gray-600',
              )}
              onClick={() => setDrawerOpen(true)}
            >
              <MaterialIcon icon="menu" className="text-[26px] leading-8" />
            </button>

            <MobileDrawer
              isOpen={isDrawerOpen}
              onClose={() => setDrawerOpen(false)}
              onSignIn={onSignIn}
              onSignUp={onSignUp}
            />
          </div>

          {/* DESKTOP */}

          <div className="hidden md:flex">
            {authStatus === 'loggedIn' && (
              <Transition
                as="div"
                className="flex items-center"
                appear={true}
                show={true}
                enter="transition-all duration-200 delay-200"
                enterFrom="opacity-0 translate-y-5"
                enterTo="opacity-100 translate-y-0"
                leave="transition-all duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-5"
              >
                {isHomePage && (
                  <div className="mr-14">
                    <LandlordsButton floating={isTransparent} />
                  </div>
                )}

                <div className="flex items-center">
                  <div className="flex items-center space-x-2">
                    <InboxHeaderButton isFloating={isTransparent} />
                    <NotificationsButton isFloating={isTransparent} />
                    <LanguageMenuButton isFloating={isTransparent} />
                  </div>

                  <div className="ml-8">
                    <ProfileMenu floating={isTransparent} />
                  </div>
                </div>
              </Transition>
            )}

            {authStatus === 'loggedOut' && (
              <Transition
                as="div"
                className="flex items-center"
                appear={true}
                show={true}
                enter="transition-all duration-200 delay-200"
                enterFrom="opacity-0 translate-y-5"
                enterTo="opacity-100 translate-y-0"
                leave="transition-all duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-5"
              >
                {isHomePage && (
                  <div className="mr-14">
                    <LandlordsButton floating={isTransparent} />
                  </div>
                )}

                <div className="flex items-center space-x-5">
                  <LanguageMenuButton isFloating={isTransparent} />

                  <div className="hidden md:flex md:items-center md:justify-between">
                    <div className="flex items-center">
                      <button
                        onClick={onSignIn}
                        className={clsx(
                          'text-base font-medium px-4 py-2 rounded-md',
                          isTransparent
                            ? 'bg-white bg-opacity-30 text-white hover:text-opacity-90'
                            : 'border text-gray-500 hover:text-gray-900',
                        )}
                      >
                        Sign in
                      </button>

                      <button
                        onClick={onSignUp}
                        className="ml-4 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-primary-600"
                      >
                        Sign up
                      </button>
                    </div>
                  </div>
                </div>
              </Transition>
            )}
          </div>

          <>
            <AuthModal
              type="login"
              isOpen={isSignInModalOpen}
              onClose={() => setSignInModalOpen(false)}
            />
            <AuthModal
              type="signup"
              isOpen={isSignUpModalOpen}
              onClose={() => setSignUpModalOpen(false)}
            />
          </>
        </div>
      </div>
    </header>
  );
};

const LandlordsButton: React.FC<React.PropsWithChildren<{ floating: boolean }>> = ({
  floating,
}) => {
  const { landlords: tx } = useTxs();

  return (
    <a
      href="#landlords"
      className={clsx(
        'h-9 flex-center px-4 py-2 rounded-md cursor-pointer mr-0 text-sm font-medium tracking-wide transition-colors',
        floating ? 'text-white bg-white bg-opacity-30' : 'border text-gray-500',
        floating ? 'hover:bg-opacity-20' : 'hover:bg-blackAlpha-100',
        floating ? 'active:bg-opacity-20' : 'active:bg-blackAlpha-100',
      )}
    >
      {tx.label}
    </a>
  );
};

const useTxs = createTxs({
  en: {
    landlords: { label: 'FOR LANDLORDS' },
  },
  it: {
    landlords: { label: 'PER PROPRIETARI' },
  },
});

export default Header;

import { Menu, Transition } from '@headlessui/react';
import { ConfirmDialog, MaterialIcon, MaterialIconName } from '@koolumbus/web/ui';
import { useLocale } from '@koolumbus/web/utils';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { Fragment, useState } from 'react';
import { useAuthCtx } from '../../../lib/context/auth.context';
import { primaryMenuItems, secondaryMenuItems } from './menu-items';

interface ProfileMenu {
  floating: boolean;
}

const ProfileMenu: React.FC<React.PropsWithChildren<ProfileMenu>> = ({ floating }) => {
  const {
    state: { user },
    signOut,
  } = useAuthCtx();
  const locale = useLocale();

  const [confirmLogoutOpen, setConfirmLogoutOpen] = useState(false);
  const onCloseConfirmLogout = () => setConfirmLogoutOpen(false);

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className={clsx(
              'inline-flex flex-center w-full rounded-full p-[3px] pl-3',
              floating
                ? 'bg-white bg-opacity-30 hover:bg-opacity-40'
                : 'bg-white hover:bg-gray-100',
              !floating && 'border border-gray-200',
            )}
          >
            <span className="flex-center mr-3">
              <MaterialIcon
                icon="menu"
                className={clsx('text-xl leading-5', floating ? 'text-white' : 'text-gray-500')}
              />
            </span>

            <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
              <svg className="h-full w-full text-gray-400" fill="currentColor" viewBox="0 0 24 24">
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </span>
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="px-4 py-3">
              <p className="text-sm text-gray-400">Signed in as</p>
              <p className="text-md text-gray-600 truncate">{user?.email ?? 'Unknown email'}</p>
            </div>

            <div className="py-1">
              {primaryMenuItems.map((item, i) => (
                <ProfileMenuItem
                  key={i}
                  label={item.label[locale]}
                  action={item.href}
                  icon={item.icon}
                />
              ))}
            </div>

            <div className="py-1">
              {secondaryMenuItems.map((item, i) => (
                <ProfileMenuItem
                  key={i}
                  label={item.label[locale]}
                  action={item.href}
                  icon={item.icon}
                />
              ))}

              <ProfileMenuItem
                label="Logout"
                icon="lock-open"
                action={() => setConfirmLogoutOpen(true)}
              />
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      <ConfirmDialog
        onClose={onCloseConfirmLogout}
        isOpen={confirmLogoutOpen}
        onConfirm={signOut}
        title="Logout"
        body="Do you really want to logout?"
        variant="warning"
      />
    </>
  );
};

interface ProfileMenuItemProps {
  icon: MaterialIconName;
  action: string | (() => void);
  label: string;
}

const ProfileMenuItem: React.FC<React.PropsWithChildren<ProfileMenuItemProps>> = ({
  icon,
  action,
  label,
}) => {
  const router = useRouter();

  const onClick = () => {
    if (typeof action === 'string') {
      router.push(action);
    } else {
      action();
    }
  };

  return (
    <Menu.Item as="button" className="w-full text-left" onClick={onClick}>
      {({ active }) => (
        <p
          className={clsx(
            'group flex items-center px-4 py-2 cursor-pointer font-medium',
            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
          )}
        >
          <MaterialIcon
            className="mr-4 text-lg text-gray-400"
            variant="outlined"
            icon={icon}
            aria-hidden="true"
          />
          {label}
        </p>
      )}
    </Menu.Item>
  );
};

export default ProfileMenu;

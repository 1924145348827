import { Menu, Transition } from '@headlessui/react';
import { Locale } from '@koolumbus/shared/config';
import { useIntl } from '@koolumbus/web/utils';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import { HeaderButton } from './HeaderButton';

export interface LanguageMenuButtonProps {
  isFloating: boolean;
}

const options: Array<{ label: string; value: Locale }> = [
  { label: 'English', value: 'en' },
  { label: 'Italian', value: 'it' },
];

export const LanguageMenuButton: React.FC<React.PropsWithChildren<LanguageMenuButtonProps>> = ({
  isFloating,
}) => {
  const { setLocale } = useIntl();

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          as={HeaderButton}
          tooltip="change language"
          icon="language"
          isFloating={isFloating}
        />
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {options.map((opt, i) => (
              <Menu.Item
                key={i}
                as="button"
                className="w-full text-left"
                onClick={() => setLocale(opt.value)}
              >
                {({ active }) => (
                  <p
                    className={clsx(
                      'block px-4 py-2 cursor-pointer',
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    )}
                  >
                    {opt.label}
                  </p>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

import clsx from 'clsx';
import React from 'react';
import { Spinner, SpinnerSize } from './Spinner';

const classes = {
  base: 'btn-base',
  variants: {
    primary: 'btn-primary',
    secondary: 'btn-secondary',
    outline: 'btn-outline',
    ghost: 'btn-ghost',
    danger: 'btn-danger',
  },
  sizes: {
    normal: {
      xs: 'btn-xs',
      sm: 'btn-sm',
      md: 'btn-md',
      lg: 'btn-lg',
      xl: 'btn-xl',
    },
    icon: {
      xs: 'icon-btn-xs',
      sm: 'icon-btn-sm',
      md: 'icon-btn-md',
      lg: 'icon-btn-lg',
      xl: 'icon-btn-xl',
    },
  },
  icons: {
    left: {
      xs: '-ml-0.5 mr-2 h-4 w-4',
      sm: '-ml-0.5 mr-2 h-4 w-4',
      md: '-ml-1 mr-2 h-5 w-5',
      lg: '-ml-0.5 mr-2.5 h-5 w-5',
      xl: '-ml-0.5 mr-2.5 h-5 w-5',
    },
    right: {
      xs: 'ml-2 -mr-0.5 h-4 w-4',
      sm: 'ml-2 -mr-0.5 h-4 w-4',
      md: 'ml-2 -mr-1 h-5 w-5',
      lg: 'ml-2.5 -mr-0.5 h-5 w-5',
      xl: 'ml-2.5 -mr-0.5 h-5 w-5',
    },
  },
};

export type ButtonSize = keyof typeof classes.sizes.normal;
export type ButtonVariant = keyof typeof classes.variants;

type NativeProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export interface ButtonProps {
  size?: ButtonSize;
  variant?: ButtonVariant;
  className?: string;

  // states
  isDisabled?: boolean;
  isLoading?: boolean;

  // handlers
  onClick?: React.MouseEventHandler<HTMLButtonElement>;

  // icons
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;

  // html native props
  type?: NativeProps['type'];
}

const getProps = (value: ButtonProps) => {
  return {
    size: value.size ?? 'md',
    variant: value.variant ?? 'primary',
    type: value.type ?? 'button',
    isDisabled: value.isDisabled ?? false,
    isLoading: value.isLoading ?? false,
    ...value,
  };
};

const getSpinnerSize = (btnSize: ButtonSize): SpinnerSize => {
  switch (btnSize) {
    case 'xs':
      return 'xs';
    case 'sm':
      return 'xs';
    case 'md':
      return 'sm';
    case 'lg':
      return 'sm';
    case 'xl':
      return 'sm';
    default:
      return 'sm';
  }
};

export const Button = React.forwardRef<HTMLButtonElement, React.PropsWithChildren<ButtonProps>>(
  ({ children, ...props }, ref) => {
    const { variant, type, size, isDisabled, isLoading } = getProps(props);
    const disabled = isLoading || isDisabled;

    return (
      <button
        ref={ref}
        type={type || 'button'}
        className={clsx(
          classes.base,
          classes.sizes.normal[size],
          classes.variants[variant],
          disabled ? 'cursor-not-allowed' : 'cursor-pointer',
          props.className,
        )}
        disabled={disabled}
        onClick={props.onClick}
      >
        {isLoading && (
          <Spinner
            size={getSpinnerSize(size)}
            className={clsx('text-white mr-2.5', {
              'border-primary-400': variant === 'primary',
              'border-red-400': variant === 'danger',
            })}
          />
        )}
        {props.leftIcon && !isLoading && (
          <span className={classes.icons.left[size]}>{props.leftIcon}</span>
        )}
        {children}
        {props.rightIcon && !isLoading && (
          <span className={classes.icons.right[size]}>{props.rightIcon}</span>
        )}
      </button>
    );
  },
);

export interface IconButtonProps extends Omit<ButtonProps, 'leftIcon' | 'rightIcon'> {
  icon: React.ReactNode;
  ['aria-label']: string;
}

const iconWrapper: Record<ButtonSize, string> = {
  xs: 'h-3.5 w-3.5',
  sm: 'h-4 w-4',
  md: 'w-5 h-5',
  lg: 'h-6 w-6',
  xl: 'h-7 w-7',
};

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const { type, variant, size, isDisabled, isLoading } = getProps(props);
  const disabled = isLoading || isDisabled;

  return (
    <button
      ref={ref}
      type={type || 'button'}
      className={clsx(
        classes.base,
        classes.variants[variant],
        classes.sizes.icon[size],
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        props.className,
      )}
      disabled={disabled}
      onClick={props.onClick}
      aria-label={props['aria-label']}
    >
      {isLoading ? (
        <Spinner size={size} className="text-white" />
      ) : (
        <span className={clsx('flex-center', iconWrapper[size])}>{props.icon}</span>
      )}
    </button>
  );
});

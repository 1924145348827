import { NotificationWithData } from '@koolumbus/shared/data';
import { date } from '@koolumbus/shared/utils';
import { NotificationEvent } from '@prisma/client';

export type NotificationGroup = {
  event: NotificationEvent;
  ids: [string, ...string[]];
  resourceId: string;
  resource: NotificationWithData['auction'] | NotificationWithData['booking'];
  count: number;
  lastNotificationDate: Date;
};

const auctionEvents: NotificationEvent[] = [
  'AUCTION_BID_ACCEPTED',
  'AUCTION_BID_REJECTED',
  'AUCTION_EXPIRED',
  'NEW_AUCTION_BID',
];

const bookingEvents: NotificationEvent[] = ['BOOKING_CONFIRMED', 'BOOKING_REJECTED', 'NEW_BOOKING'];

export const isAuctionEvent = (event: NotificationEvent) => {
  return auctionEvents.includes(event);
};

export const isBookingEvent = (event: NotificationEvent) => {
  return bookingEvents.includes(event);
};

export const getNotificationResource = (
  item: NotificationWithData,
): NotificationGroup['resource'] => {
  if (isAuctionEvent(item.event)) {
    return item.auction;
  }

  if (isBookingEvent(item.event)) {
    return item.booking;
  }

  return null;
};

export const getNotificationGroupKey = (event: NotificationEvent, resourceId: string): string => {
  switch (event) {
    case 'NEW_BOOKING':
      return `${event}`;
    default:
      return `${event}~${resourceId}`;
  }
};

export const getNotificationsGroups = (items: NotificationWithData[]): NotificationGroup[] => {
  const result: Record<string, NotificationGroup> = {};

  for (const item of items) {
    const resource = getNotificationResource(item);
    if (!resource) {
      continue;
    }

    const resourceId = resource.id;

    const itemKey = getNotificationGroupKey(item.event, resourceId);

    if (itemKey in result) {
      const group = result[itemKey];

      group.count += 1;
      group.ids.push(item.id);

      if (date(group.lastNotificationDate).isBefore(item.createdAt)) {
        group.lastNotificationDate = item.createdAt;
      }
    } else {
      result[itemKey] = {
        event: item.event,
        ids: [item.id],
        count: 1,
        resourceId,
        resource,
        lastNotificationDate: item.createdAt,
      };
    }
  }

  const values = Object.values(result);
  values.sort((a, b) => {
    return date(a.lastNotificationDate).isBefore(b.lastNotificationDate, 'millisecond') ? 1 : -1;
  });

  return values;
};

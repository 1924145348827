import { Icon } from '@iconify/react';
import { HTMLAttributes } from 'react';

export type MaterialIconName =
  | 'favorite'
  | 'favorite-border'
  | 'comment'
  | 'insert-chart'
  | 'local-atm'
  | 'star'
  | 'attach-file'
  | 'toggle-on'
  | 'notes'
  | 'meeting-room'
  | 'lock'
  | 'text-snippet'
  | 'garage'
  | 'balcony'
  | 'public'
  | 'assignment'
  | 'public-off'
  | 'cleaning-services'
  | 'door-front'
  | 'file-present'
  | 'toggle-off'
  | 'vpn-key'
  | 'person'
  | 'list'
  | 'work'
  | 'supervised-user-circle'
  | 'lock-open'
  | 'face'
  | 'map'
  | 'euro'
  | 'date-range'
  | 'place'
  | 'assignment-ind'
  | 'euro'
  | 'sort'
  | 'filter-list'
  | 'card-travel'
  | 'book'
  | 'settings'
  | 'delete'
  | 'add'
  | 'straighten'
  | 'check'
  | 'share'
  | 'info'
  | 'chair-alt'
  | 'bedroom-child'
  | 'ac-unit'
  | 'king-bed'
  | 'single-bed'
  | 'weekend'
  | 'bathroom'
  | 'link'
  | 'facebook'
  | 'sms'
  | 'collections'
  | 'help'
  | 'help-outline'
  | 'account-circle'
  | 'message'
  | 'home'
  | 'notifications'
  | 'language'
  | 'dining'
  | 'report'
  | 'people'
  | 'help'
  | 'visibility'
  | 'close'
  | 'clear'
  | 'chevron-left'
  | 'chevron-right'
  | 'fmd-good'
  | 'wifi'
  | 'local-laundry-service'
  | 'water'
  | 'expand-less'
  | 'expand-more'
  | 'menu'
  | 'arrow-back'
  | 'arrow-forward'
  | 'send'
  | 'visibility-off'
  | 'error'
  | 'error-outline'
  | 'check-circle'
  | 'archive'
  | 'unarchive'
  | 'edit'
  | 'cloud-download'
  | 'keyboard-arrow-up'
  | 'keyboard-arrow-left'
  | 'keyboard-arrow-right'
  | 'keyboard-arrow-down'
  | 'keyboard-backspace'
  | 'today'
  | 'email'
  | 'search'
  | 'phone'
  | 'done'
  | 'content-copy'
  | 'launch'
  | 'checklist'
  | 'fact-check'
  | 'playlist-add-check-circle'
  | 'inbox'
  | 'access-time';

interface MaterialIconsProps {
  icon: MaterialIconName;
  variant?: 'solid' | 'outlined' | 'two-tone' | 'round';
  className?: string;
  style?: HTMLAttributes<HTMLSpanElement>['style'];
}

export const MaterialIcon: React.FC<React.PropsWithChildren<MaterialIconsProps>> = ({
  icon,
  variant = 'solid',
  className,
  style,
}) => {
  let variantPrefix: string;

  switch (variant) {
    case 'solid':
      variantPrefix = 'baseline';
      break;
    case 'outlined':
      variantPrefix = 'outline';
      break;
    case 'round':
      variantPrefix = 'round';
      break;
    case 'two-tone':
      variantPrefix = 'twotone';
      break;
  }

  return <Icon icon={`ic:${variantPrefix}-${icon}`} className={className} style={style} />;
};

import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import hotToast, { Toast as ToastInstance } from 'react-hot-toast';
import { MaterialIcon, MaterialIconName } from './MaterialIcon';

export type ToastVariant = 'info' | 'success' | 'warning' | 'error';

const icons: Record<ToastVariant, MaterialIconName> = {
  info: 'info',
  warning: 'error',
  error: 'error',
  success: 'check-circle',
};

const Toast: React.FC<
  React.PropsWithChildren<{ t: ToastInstance; message: string; variant: ToastVariant }>
> = ({ t, message, variant }) => {
  const iconProps = {
    'aria-hidden': true,
    className: clsx('text-2xl', {
      'text-green-400': variant === 'success',
      'text-yellow-400': variant === 'warning',
      'text-red-400': variant === 'error',
      'text-blue-400': variant === 'info',
    }),
  };

  return (
    <Transition
      appear={true}
      show={t.visible}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0"
      enterTo="translate-y-0 opacity-100"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="px-4 py-3">
          <div className="flex items-center">
            <div className="flex-center">
              <MaterialIcon icon={icons[variant]} {...iconProps} />
            </div>

            <div className="ml-3 w-0 flex-1">
              <p className="text-[15px] text-gray-800">{message}</p>
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              <button
                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => hotToast.dismiss(t.id)}
              >
                <span className="sr-only">Close</span>
                <MaterialIcon icon="clear" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export const showToast = (message: string, variant: ToastVariant = 'info', duration = 5000) => {
  hotToast.custom((t) => <Toast t={t} message={message} variant={variant} />, { duration });
};

export const toast = {
  info: (message: string, duration?: number) => {
    return showToast(message, 'info', duration);
  },
  success: (message = 'Operazione effettuata con successo', duration?: number) => {
    return showToast(message, 'success', duration);
  },
  warning: (message: string, duration?: number) => {
    return showToast(message, 'warning', duration);
  },
  error: (message = 'An unexpected error occurred', duration?: number) => {
    return showToast(message, 'error', duration);
  },
};

export const useToast = () => toast;
